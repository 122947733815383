import { useState, useEffect, useRef } from 'react';
import { refreshAccessToken } from '@store/feature/chat/chatApi';

const useWS = (url) => {
    const [isConnected, setIsConnected] = useState(false);
    const webSocketRef = useRef(null);
    const reconnectTimeoutRef = useRef(1000);
    const maxTimeout = 60000;
    const keepRetryingRef = useRef(true);

    const connectWebSocketInstance = (url, token) => {
        const ws = new WebSocket(`${url}?token=${token}`);

        ws.onopen = () => {
            console.log('WebSocket connected');
            setIsConnected(true);
            reconnectTimeoutRef.current = 1000; // Reset timeout on successful connection
        };

        ws.onclose = () => {
            console.warn('WebSocket disconnected');
            setIsConnected(false);
            reconnectWebSocket(url);
        };

        ws.onerror = (error) => {
            console.error('WebSocket encountered an error', error);
        };

        webSocketRef.current = ws;
    };

    const reconnectWebSocket = async (url) => {
        if (!keepRetryingRef.current) {
            console.warn('Reconnection attempts halted.');
            return;
        }

        if (reconnectTimeoutRef.current >= maxTimeout) {
            console.error('Max reconnection timeout reached. Stopping retries.');
            keepRetryingRef.current = false;
            return;
        }

        console.log(
            `Attempting to reconnect in ${reconnectTimeoutRef.current / 1000} seconds...`
        );

        setTimeout(async () => {
            try {
                const tokenResp = await refreshAccessToken();
                if (tokenResp?.error) {
                    console.error('Token refresh failed:', tokenResp.error);
                    if (
                        ['Invalid refresh token', 'No refresh token found'].includes(
                            tokenResp.error
                        )
                    ) {
                        keepRetryingRef.current = false;
                    }
                } else if (tokenResp?.data) {
                    console.log('Token refreshed successfully. Reconnecting...');
                    connectWebSocketInstance(url, tokenResp.data);
                }
            } catch (error) {
                console.error('Error during token refresh:', error);
            } finally {
                reconnectTimeoutRef.current = Math.min(
                    reconnectTimeoutRef.current * 2,
                    maxTimeout
                );
            }
        }, reconnectTimeoutRef.current);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            connectWebSocketInstance(url, token);
        } else {
            console.error('No token found. WebSocket cannot connect.');
        }

        return () => {
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }
            keepRetryingRef.current = false;
            reconnectTimeoutRef.current = 1000; // Reset timeout for future connections
        };
    }, [url]); // URL is the only dependency

    return { isConnected, webSocket: webSocketRef.current, reconnectWebSocket };
};

export const setupMessageListener = (webSocket, callback) => {
    if (!webSocket) {
        console.error('WebSocket not connected.');
        return;
    } 

    webSocket.onmessage = (message) => {
        const data = JSON.parse(message.data);
        callback(data);
    };
}

export default useWS;
