import { Box, Typography } from '@mui/material';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import UserAvatar from '../components/UserAvatar';
import MessageBox from '../components/MessageBox';
import ChatDetails from '../components/ChatDetails';
import {
    useGetChatProfileQuery,
    useGetChatUserQuery,
    useGetChatMessagesQuery,
    useReadChatMessageMutation
} from '@store/feature/chat/chatApi';
import SendChat from '../components/SendChat';
import { FiCommand } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { FaArrowDown } from 'react-icons/fa6';
import Typing from '../../../../components/Typing';

const ChatMessageContent = () => {
    const { id } = useParams();
    let { data: profile } = useGetChatProfileQuery();
    let { data: userProfile, isLoading, error } = useGetChatUserQuery(id);
    let { data: messages } = useGetChatMessagesQuery(id);
    let [readMessage] = useReadChatMessageMutation();

    const [isThinking, setIsThinking] = useState(false);

    // If the user is chatbot and last message is from the user, show thinking
    useEffect(() => {
        if (userProfile?.user?.username === 'chatbot' && messages?.length > 0) {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage?.from === profile?.uuid) {
                setIsThinking(true);
            } else {
                setIsThinking(false);
            }
        }
    }, [profile, userProfile, messages]);

    const [showChatDetails, setShowChatDetails] = useState(false);
    const bottomRef = useRef(null);
    const [scrollToLatestMessage, setScrollToLatestMessage] = useState(true);
    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'auto' });
        }
        setTimeout(() => setScrollToLatestMessage(true), 100);
    }, [messages, isThinking]);

    const handleNotInView = useCallback(() => {
        setScrollToLatestMessage(false);
    }, []);

    const handleInView = useCallback(() => {
        setScrollToLatestMessage(true);
    }, []);

    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    handleNotInView();
                } else {
                    handleInView();
                }
            });
        };

        const observerOptions = {
            root: null, // Use the viewport as the root
            threshold: 0 // Trigger when the element is not visible at all
        };

        const observer = new IntersectionObserver(
            observerCallback,
            observerOptions
        );

        if (bottomRef.current) {
            observer.observe(bottomRef.current);
        }

        return () => {
            if (bottomRef.current) {
                observer.unobserve(bottomRef.current);
            }
        };
    }, [messages, handleNotInView, handleInView]);

    const handleShowChatDetails = () => {
        setShowChatDetails(!showChatDetails);
    };

    const handleChatDetailsClose = () => {
        setShowChatDetails(false);
    };

    useEffect(() => {
        if (id && profile?.uuid !== id && messages?.length > 0) {
            const lastMessage = messages[messages.length - 1];
            if (
                lastMessage?.from === id &&
                (lastMessage?.status === 'sent' ||
                    lastMessage?.status === 'delivered')
            ) {
                readMessage(lastMessage?.uuid);
            }
        }
    }, [id, profile, messages, readMessage]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-20 gap-4">
                <span className="text-teal-400 text-xl font-semibold">
                    Loading...
                </span>
                <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
            </div>
        );
    }

    if (error) {
        userProfile = {};
        messages = [];
    }

    const handleScrollDown = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setTimeout(() => setScrollToLatestMessage(true), 500);
    };

    // Function to format date to MM/DD/YYYY
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    };

    return (
        <Box sx={{ display: 'flex', gap: '3px' }}>
            <Box sx={{ flex: 1 }}>
                <Box
                    sx={{
                        backgroundColor: '#27485D',
                        height: '64px',
                        display: 'flex',
                        alignItems: 'center',
                        px: '16px',
                        position: 'sticky',
                        top: 0,
                        gap: '8px',
                        borderRadius: '4px 4px 0 0'
                    }}
                >
                    <div
                        className="hover:cursor-pointer flex items-center gap-3"
                        onClick={handleShowChatDetails}
                    >
                        <UserAvatar
                            imgSource={userProfile?.user?.image_url}
                            fullName={userProfile?.user?.fullname}
                            isActive={
                                userProfile?.user?.status === 'active' ||
                                userProfile?.user?.uuid === profile?.uuid
                            }
                        />
                        <Typography
                            sx={{
                                width: '100%',
                                display: 'flex',
                                color: 'white',
                                cursor: 'pointer'
                            }}
                            variant="body2"
                            onClick={handleShowChatDetails}
                        >
                            {userProfile?.user?.fullname}
                        </Typography>
                    </div>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100dvh - 165px)'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            pt: '12px',
                            overflowY: 'scroll',
                            height: 'calc(100dvh - 265px)'
                        }}
                    >
                        <Box sx={{ mt: 'auto', width: '100%' }}>
                            {messages && messages.length > 0 ? (
                                messages.map((text, index) => {
                                    const currentMessageDate = formatDate(
                                        text?.created_at
                                    );
                                    const previousMessageDate =
                                        index > 0
                                            ? formatDate(
                                                  messages[index - 1]
                                                      ?.created_at
                                              )
                                            : null;

                                    const showDateHeader =
                                        index === 0 ||
                                        currentMessageDate !==
                                            previousMessageDate;

                                    return (
                                        <React.Fragment key={index}>
                                            {showDateHeader && (
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        my: 2
                                                    }}
                                                >
                                                    <Typography
                                                        variant="caption"
                                                        color="textSecondary"
                                                    >
                                                        {currentMessageDate}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <MessageBox
                                                id={text?.uuid}
                                                messageText={text?.body}
                                                timestamp={text?.created_at}
                                                isRight={
                                                    text?.from === profile?.uuid
                                                }
                                                status={text?.status}
                                                files={text?.files}
                                            />
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <div className="flex justify-center items-center min-h-screen w-full pt-[300px]">
                                    <p>No messages available!</p>
                                </div>
                            )}

                            {isThinking && <Typing />}

                            <div id="bottom" ref={bottomRef} className="border-2 border-white"/>
                        </Box>
                    </Box>
                    {!scrollToLatestMessage && messages && (
                        <div className="relative w-full">
                            <span className="absolute left-1/2 transform -translate-x-1/2 bottom-2">
                                <button
                                    className="z-100 rounded-full flex justify-center items-center bg-[#2FACFE] border-1 border-gray-300 w-8 h-8"
                                    onClick={handleScrollDown}
                                >
                                    <FaArrowDown color="white" />
                                </button>
                            </span>
                        </div>
                    )}

                    <Box>
                        {userProfile?.blocked ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography>
                                    You have blocked this user.
                                </Typography>
                            </Box>
                        ) : userProfile?.blocked_by_user ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography>
                                    You are blocked by this user.
                                </Typography>
                            </Box>
                        ) : (
                            <SendChat />
                        )}
                    </Box>
                </Box>
            </Box>

            {showChatDetails && (
                <Box
                    sx={{
                        height: 'calc(100dvh - 101px)',
                        borderRadius: '8px',
                        width: '40%',
                        maxWidth: '356px',
                        overflow: 'hidden',
                        position: 'relative',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0px'
                    }}
                >
                    <ChatDetails onClose={handleChatDetailsClose} />
                </Box>
            )}
        </Box>
    );
};

export default ChatMessageContent;
